
    /deep/.el-button:focus, .el-button:hover {
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    .territory{
        /deep/.el-checkbox__label{
            color: #333333;
        }
    }
    /deep/.el-cascader-menu__list{
        min-width: 500px!important;
    }
    /deep/.el-cascader-menu .el-cascader-menu__wrap{
        height: 365px;
    }
    /deep/.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
        color: #333333!important;
    }
    /deep/.el-cascader-menu__list .is-active .el-cascader-node__label{
        color: #333333;
    }
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }

    /*/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner{*/
    /*    background-color: #FD4446;*/
    /*    border-color: #FD4446;*/
    /*}*/
    /deep/.el-radio-group .is-active .el-radio-button__inner{
        background: #F5F5F5!important;
    }
    /deep/.el-radio-group .el-radio-button--medium .el-radio-button__inner{
        border-color: #DCDFE6;
        color: #333333;
        box-shadow:none
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 20px;
        height: 20px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 18px;
    }
    /deep/.el-radio .el-radio__label{
        color: #1E63F1;
        font-size: 18px;
    }
    /deep/.el-icon-success{
        color: #20D08C;
    }
    /deep/.el-checkbox__inner:hover{
        border-color: #FD4446!important;
    }
    .resourceState{
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 50px;
            height: 50px;
            margin-left: 10px;
        }
    }
    .PlanStepThree-bj{
        /*height: calc(100vh - 200px);*/
        .drawerBox{
            padding:0 60px;
            overflow-y: auto;
            height: calc(100vh - 100px);
            .drawerBoxHeader{
                display: flex;
                flex-direction: column;
                p{
                    color: #333333;
                    font-size: 24px;
                    line-height: 1;
                }
                .span1{
                    margin-top: 20px;
                    color: #333333;
                    font-size: 16px;
                    display: inline-block;
                    height: 40px;
                    background: #EBF3FF;
                    line-height: 40px;
                    padding-left: 30px;
                    border: 1px solid #D7E8FF;
                }
                .span2{
                    margin-top: 10px;
                    color: #333333;
                    font-size: 16px;
                    b{
                        color: #FD4446;
                        font-weight: 400;
                    }
                }
                .selectRadio{
                    margin-top: 20px;
                }
            }
            .drawerBoxTable{
                .drawerConfirm{
                    display: flex;
                    justify-content: space-between;
                    padding-top: 40px;
                    .drawerConfirmBtn{
                        width: 120px;
                        background: #FD4446;
                        border-radius: 6px;
                        border-color: #FD4446;
                        color: #ffffff;
                    }
                }
            }

        }
    }
    .PlanStepThree{
        padding: 20px 30px;
        .PlanStepThreeHeader{
            color: #333333;
            font-size: 28px;
        }
        .uniNameBox{
            /*padding-bottom: 30px;*/
            span{
                color: #333333;
                font-size: 18px;
                font-weight: 500;
            }
            .unitName{
                display: flex;
                align-items: center;
                padding-left: 56px;
                padding-top: 70px;
            }
            .unitPrice{
                display: flex;
                align-items: center;
                padding-left: 132px;
                padding-top: 50px;
            }
        }
    }
    .PlanStepThree2{
        padding: 20px 30px;
        .PlanStepThree2Header{
            padding-left: 74px;
            span:nth-child(1){
                font-size: 18px;
            }
            .headerBtn{
                height: 36px;
                line-height: 0;
            }
        }
    }
    .PlanStepThree3{
        padding: 20px 30px;
        .PlanStepThree3Header{
            padding-left: 56px;
            span:nth-child(1){
                font-width: 500;
            }
            span:nth-child(2){
                margin-left: 20px;
            }
            span{
                font-size: 18px;
                color: #333;
                font-weight: 400;
            }
            i{
                margin: 0 10px;
            }
        }
        .PlanStepThree3Table{
            padding: 20px;
            border: 1px solid rgba(151, 151, 151,0.3);
            margin-left: 150px;
            margin-top: 16px;
            .PlanStepThree3TableHeader{
                .Header1{
                    display: flex;
                    align-items: center;
                }
                .Header2{
                    margin-top: 14px;
                }
            }
            .tableBox{
                margin-left: 110px;
                margin-top: 20px;
                .table-header{
                    height: 50px;
                    background: #F0F0F0;
                    color: #333333;
                    font-size: 18px;
                    line-height: 50px;
                    padding-left: 20px;
                }
            }
        }
    }
    .cut-off{
        width: 100%;
        height: 20px;
        background: #F7F9FB;
    }
    .cut-off-btn{
        padding: 30px;
        width: 100%;
        background: #F7F9FB;
        .offBtn{
            width: 140px;
            height: 50px;
            font-size: 20px;
        }
        .offBtn:nth-child(2){
            background: #FD4446;
            border-color: #FD4446;
            color: #ffffff;
        }
    }
    .territory{
        overflow-y: auto;
        height: calc(100vh - 100px);
        padding: 0 60px;
        .territory-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #333333;
            font-size: 24px;
            font-weight: 500;
        }
        .territory-select{
            margin-top: 20px;
        }
        .territory-btn{
            padding-top: 100px;
            display: flex;
            justify-content: space-between;
            .btns{
                width: 120px;
                height: 50px;
                background-color: #FD4446;
                border-color: #FD4446;
                color: #ffffff;
                font-size: 20px;
            }
        }
    }
